body {
  background-color: black;
}
h1, p {
font-family: 'Syne Mono', monospace;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 250px;
}
.btn-floating {
  border-radius: 50px;
}
footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    color: white;
    text-align: center;
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
  }
.spinners{
  display: flex;
  align-items: center;
  justify-content: center;
}